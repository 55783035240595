import React, { FC } from "react"
import styled from "styled-components"

interface InfoItemProps {
  rata: string
  desc: string
  label: string
}

const Container = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  ${({ theme }) => theme.breakpoints.up("sm")} {
    flex-direction: row;
    align-items: center;
  }
`

const Wrapper = styled.div`
  display: flex;
  align-items: center;
`

const Box = styled.div`
  background-color: ${({ theme }) => theme.colors.primary};
  color: #fff;
  padding: 10px 20px;
  font-size: 18px;
  border-radius: 10px;
  min-width: 130px;
  text-align: center;
  box-shadow: 0 0 15px -2px #000;
  ${({ theme }) => theme.breakpoints.up("sm")} {
    font-size: 24px;
    min-width: 160px;
  }
`

const Desc = styled.div`
  background-color: ${({ theme }) => theme.colors.secoundary};
  color: #fff;
  padding: 5px 20px;
  font-size: 16px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  ${({ theme }) => theme.breakpoints.up("sm")} {
    font-size: 20px;
    min-width: 160px;
  }
`

const Label = styled.p`
  color: #a0a0a0;
  font-size: 18px;
  padding: 5px 10px;
  ${({ theme }) => theme.breakpoints.up("sm")} {
    font-size: 24px;
  }
`

export const InfoItem: FC<InfoItemProps> = ({ desc, rata, label }) => {
  return (
    <Container>
      <Wrapper>
        <Box>{rata}</Box>
        <Desc>{desc}</Desc>
      </Wrapper>
      <Label>{label}</Label>
    </Container>
  )
}

export default InfoItem
